export default class Seo {
    static click(gtag, product, type, position, list) {

        let eventParams = {
            "ecommerce": {
                "click": {
                    "actionField": {
                        "list": list
                    },
                    "products": [{
                        "id": product.id,
                        "name": product.name,
                        "price": product.price,
                        "brand": "iWengo",
                        "category": type,
                        "position": position // Позиция товара на странице
                    }]
                }
            },
            'event': 'gtm-ee-event',
            'gtm-ee-event-category': 'Enhanced Ecommerce',
            'gtm-ee-event-action': 'Product Click',
            'gtm-ee-event-non-interaction': 'False',
        };

        gtag.event('gtm-ee-event', eventParams);

        window.dataLayer.push({
            ecommerce: {
                currencyCode: 'RUB',
                detail: {
                    products: [
                        {
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            brand: 'iWengo',
                            category: type,
                        },
                    ],
                },
            },
        });
    }

    static inCart(gtag, product, type) {
        let eventParams = {
            "ecommerce": {
                "currencyCode": "RUB",
                "add": {
                    "products": [{
                        "id": product.id,
                        "name": product.name,
                        "price": product.price,
                        "brand": 'iWengo',
                        "category": type,
                        "quantity": 1
                    }]
                }
            },
            'event': 'gtm-ee-event',
            'gtm-ee-event-category': 'Enhanced Ecommerce',
            'gtm-ee-event-action': 'Product Add Cart',
            'gtm-ee-event-non-interaction': 'False',
        };

        gtag.event('gtm-ee-event', eventParams);


        window.dataLayer.push({
            ecommerce: {
                currencyCode: 'RUB',
                add: {
                    products: [
                        {
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            brand: 'iWengo',
                            category: type,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    }

    static deleteFromCart(gtag, product, type) {

        let eventParams = {
            "ecommerce": {
                "currencyCode": "RUB",
                "remove": {
                    "products": [{
                        "id": product.id,
                        "name": product.name,
                        "price": product.price,
                        "brand": 'iWengo',
                        "category": type,
                        "quantity": 1
                    }],
                },
            },
            'event': 'gtm-ee-event',
            'gtm-ee-event-category': 'Enhanced Ecommerce',
            'gtm-ee-event-action': 'Product Remove Cart',
            'gtm-ee-event-non-interaction': 'False',
        };

        gtag.event('gtm-ee-event', eventParams);

        window.dataLayer.push({
            ecommerce: {
                currencyCode: 'RUB',
                remove: {
                    products: [
                        {
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            brand: 'iWengo',
                            category: type,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    }

    static goToCart(gtag, products) {

        let eventParams = {
            'ecommerce': {
                'currencyCode': 'RUB',
                'checkout': {
                    'actionField': {'step': 1},
                    'products': Seo.getProductListByCartItems(products)
                },
            },
            'event': 'gtm-ee-event',
            'gtm-ee-event-category': 'Enhanced Ecommerce',
            'gtm-ee-event-action': 'Checkout Step 1',
            'gtm-ee-event-non-interaction': 'False',
        };

        gtag.event('gtm-ee-event', eventParams);
    }

    static goToPay(gtag, products) {
        let eventParams = {
            'ecommerce': {
                'currencyCode': 'RUB',
                'checkout': {
                    'actionField': {'step': 2},
                    'products': Seo.getProductListByCartItems(products)
                },
            },
            'event': 'gtm-ee-event',
            'gtm-ee-event-category': 'Enhanced Ecommerce',
            'gtm-ee-event-action': 'Checkout Step 1',
            'gtm-ee-event-non-interaction': 'False',
        };

        gtag.event('gtm-ee-event', eventParams);
    }

    static cartPayed(gtag, products, order) {
        let productsEcom = Seo.getProductListByCartItems(products);
        let eventParams = {
            'ecommerce': {
                'currencyCode': 'RUB',
                'purchase': {
                    'actionField': {
                        'id': order.id,
                        'revenue': order.price,
                        'shipping': 0,
                    },
                    'products': productsEcom,
                }
            },
            'event': 'gtm-ee-event',
            'gtm-ee-event-category': 'Enhanced Ecommerce',
            'gtm-ee-event-action': 'Purchase',
            'gtm-ee-event-non-interaction': 'False',
        };

        gtag.event('gtm-ee-event', eventParams);

        window.dataLayer.push({
            ecommerce: {
                currencyCode: 'RUB',
                actionField: {
                    "id" : order.id,
                },
                purchase: {
                    'products': productsEcom,
                },
            },
        });
    }

    static getTypeByCartItem(product)
    {
        if (!!product.course_span_id) {
            return 'course';
        } else if (!!product.document_id) {
            return 'ducument';
        } else if (!!product.service_id) {
            return 'service';
        } else if (!!product.course_tariff_id) {
            return 'course_tariff';
        }
    }

    static getProductListByCartItems(products) {
        let productsList = [];

        for(let product of products) {
            let type = Seo.getTypeByCartItem(product);

            productsList.push({
                "id": product.id,
                "name": product.name,
                "price": product.price,
                "brand": 'iWengo',
                "category": type,
                "quantity": 1
            });
        }

        return productsList;
    }


}
