<template>
    <layout-main class="index" ref="main">
        <main class="wrap-main index__main">
            <!-- используется v-once, не обновляется. чтобы обновлялось, убрать директиву v-once -->
            <section class="section index__section index__course">
                <div class="container">
                    <v-list class="index__course-list" :items="mainCourses" key-field="id" v-once>
                        <template v-slot:item="{ item: course, index }">
                            <!--<card-service
                                class="index__course-card"
                                :isSmall="isTabletLg"
                                :service="course"
                                :isDsrc="true"
                                :seoPosition="index"
                                seoList="Homepage"
                                v-if="course.isService"
                            />
                            <card-course
                                class="index__course-card"
                                :isSmall="isTabletLg"
                                :course="course"
                                :isDsrc="true"
                                :seoPosition="index"
                                seoList="Homepage"
                                v-else
                            />-->
                            <card-course
                                class="index__course-card"
                                :isSmall="isTabletLg"
                                :course="course"
                                :isDsrc="true"
                                :seoPosition="index"
                                seoList="Homepage"
                            />
                        </template>
                    </v-list>
                </div>
            </section>

            <section class="section index__section">
                <div class="container text-center">
                    <a href="/courses" class="btn btn--white">Смотреть все программы обучения <v-svg name="line" class="svg-line" /></a>
                </div>
            </section>

            <section class="section index__section index__banner-e">
                <!--<div class="container index__telegram">
                    <div class="index__telegram__col-1">
                        <img src="../../../images/inhtml/paper_plane.png" alt="" class="index__telegram__img"/>
                    </div>
                    <div class="index__telegram__col-2">
                        <div class="index__telegram__text">Присоединяйтесь к сообществу предпринимателей от школы электронной коммерции iWENGO: бизнес, образование и личный опыт экспертов</div>
                        <div class="index__telegram__btn"><a href="https://t.me/goecommerce" target="_blank" class="btn">Подписаться</a></div>
                    </div>
                </div>-->
                <div class="container">
                    <!--<a href="/courses/onlayn-klass-e-commerce-360">
                        <img src="../../../images/inhtml/banner_e-com_600x200.png" alt="" class="index__banner-e__img"/>
                    </a>-->
                    <CourseBanner :banners="layout_CourseBanners" :homePage="true"></CourseBanner>
                </div>
            </section>

            <section
                class="section index__section index__teacher index__services"
                v-if="mainServices && mainServices.length > 0"
            >
                <div class="container">
                    <transition name="fade-in" mode="out-in">
                        <slider
                            class="index__teacher-slider"
                            title="Наши услуги"
                            key-field="id"
                            :options="smallCardsVertOptions"
                            :items="mainServices"
                            :navBetween="true"
                        >
                            <template v-slot:slide="{ item }">
                                <card-section-vert
                                    class="index__catalog-card"
                                    :header="item.name"
                                    :description="item.short_description"
                                    :image="item.image"
                                    :href="(item.link_on_main)? item.link_on_main : item.detail_url"
                                />
                            </template>
                        </slider>
                    </transition>
                </div>
            </section>

            <section
                class="section index__section index__student-company"
                v-if="student_companies && student_companies.length > 0"
            >
                <div class="container">
                    <div class="index__student-company-hl">Наши студенты</div>
                    <transition name="fade-in" mode="out-in">
                        <v-list
                            v-if="!isTablet"
                            class="index__partner-list"
                            :items="student_companies"
                            key-field="id"
                        >
                            <template v-slot:item="{ item }">
                                <card-partner class="index__partner-card" :image="item.image" />
                            </template>
                        </v-list>
                        <slider v-else class="index__partner-slider" :items="student_companies" :navBetween="true" :twoCol="true">
                            <template v-slot:slide="{ item }">
                                <card-partner class="index__partner-card" :image="item.image" />
                            </template>
                        </slider>
                    </transition>
                    <v-button
                        class="btn--white index__teacher-company-btn"
                        href="/students"
                    >Узнать больше</v-button>
                </div>
            </section>

            <section
                class="section index__section index__videos"
                v-if="videoReviews && videoReviews.length > 0"
            >
                <div class="container">
                    <transition name="fade-in" mode="out-in">
                        <slider
                            class="index__videos-slider"
                            title="Отзывы студентов"
                            :items="videoReviews"
                            hrefAll="/reviews"
                        >
                            <template v-slot:addition>
                                <v-button
                                    class="btn--white btn--video index__videos-btn"
                                    iconName="youtube"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    :href="layout_StaticBlock.more_reviews_on_youtube_link"
                                >{{ isTabletLg ? 'Ещё отзывы' : 'Ещё отзывы на Youtube' }}</v-button>
                            </template>
                            <template v-slot:slide="{ item }">
                                <card-video-review
                                    class="index__videos-card"
                                    :title="item.title"
                                    :src="item.video"
                                />
                            </template>
                        </slider>
                    </transition>
                </div>
            </section>

            <section
                class="section index__section index__benefit"
                v-if="iwengoNumbers && iwengoNumbers.length > 0"
            >
                <div class="container">
                    <div class="index__benefit-hl">Цифры и факты</div>
                    <!-- используется v-once, не обновляется. чтобы обновлялось, убрать директиву v-once -->
                    <v-list
                        :class="[
                            'index__benefit-list',
                            (iwengoNumbers.length % 4 == 0)? 'index__benefit-list__four':''
                         ]"
                        key-field="id"
                        :items="iwengoNumbers"
                        v-once
                    >
                        <template v-slot:item="{ item }">
                            <card-benefit
                                class="index__benefit-card"
                                :header="item.title"
                                :description="item.description"
                            />
                        </template>
                    </v-list>
                </div>
            </section>

            <section class="section index__section index__pseudo-btn">
                <div class="container text-center">
                    <div class="btn">Наши студенты проходят стажировку в известных компаниях. Для компаний это возможность решить задачи E-commerce силами стажёров при
поддержке профессиональных кураторов и преподавателей бизнес-школы, а для студентов - получить практику в реальном бизнесе и отзыв о своей работе
                    <a href="/about/besplatnaya-stazhirovka">Узнать подробнее <v-svg name="line" class="svg-line" /></a>
                    </div>
                </div>
            </section>

            <!--<section
                class="section index__section index__partner"
                v-if="partners && partners.length > 0"
            >
                <div class="container">
                    <div class="index__partner-hl">Наши партнеры</div>
                    <transition name="fade-in" mode="out-in">
                        <v-list
                            v-if="!isTablet"
                            class="index__partner-list"
                            :items="partners"
                            key-field="id"
                        >
                            <template v-slot:item="{ item: partner }">
                                <card-partner
                                    class="index__partner-card"
                                    :image="partner.image"
                                    :description="partner.description"
                                />
                            </template>
                        </v-list>
                        <slider v-else class="index__partner-slider" :items="partners" :navBetween="true" :twoCol="true">
                            <template v-slot:slide="{ item }">
                                <card-partner
                                    class="index__partner-card"
                                    :image="item.image"
                                    :description="item.description"
                                />
                            </template>
                        </slider>
                    </transition>
                </div>
            </section>-->

            <!--<section
                class="section index__section index__cooperation index__interns"
            >
                <div class="container">
                    <div class="index__partner-hl">Программа бесплатной стажировки</div>
                
                    <div class="index__cooperation-desc info-html">
                        <p class="mb-4">Для компаний это возможность решить задачи E-commerce силами стажёров при поддержке профессиональных кураторов и преподавателей бизнес-школы, а для студентов — получить практику в реальном бизнесе и отзыв о своей работе.</p>
                        
                        <div class="index__interns-wraps mb-4">
                            <div class="index__interns-block index__interns-block__style">
                                <div>
                                    <div class="index__partner-h2">Для компании</div>
                                    <ul class="mb-4">
                                        <li>Заполните заявку на участие компании в программе стажировки.</li>
                                        <li>Выберите в заявке, какие именно задачи можете поручить стажёрам, сколько человек готовы принять. Срок стажировки по усмотрению компании. Рекомендуемая продолжительность до одного месяца.</li>
                                        <li>Бизнес-школа iWENGO подбирает под задачи компании стажёра или группу стажёров.</li>
                                    </ul>
                                </div>
                                <p class="text-center"><a class="btn" href="https://forms.gle/o9zGPzNNfV2hCeVL7" target="_blank">Заполнить заявку</a></p>
                            </div>

                            <div class="index__interns-block index__interns-block__style">
                                <div>
                                    <div class="index__partner-h2">Для студентов</div>
                                    <ul class="mb-4">
                                        <li>Если вы завершили обучение в iWENGO и хотите пройти стажировку у наших партнёров, то оставьте заявку ниже и мы обязательно расскажем о Вас</li>
                                        <li>По окончании стажировки компания предоставляет стажёрам отзыв о работе. Возможность трудоустройства после завершения стажировки.</li>
                                    </ul>
                                </div>
                                <p class="text-center"><a class="btn" href="https://forms.gle/Nb4Gv4UWxaQxMEMT9" target="_blank">Заполнить заявку</a></p>
                            </div>
                        </div>

                        <!--<p class="index__partner-h2">В результате выигрывают все:</p>
                        <div class="index__interns-wraps">
                            <div class="index__interns-block">
                                <ul class="bullitt-lists">
                                    <li>компания решает часть задач E-commerce</li>
                                </ul>
                            </div>
                            <div class="index__interns-block">
                                <ul class="bullitt-lists">
                                    <li>студенты получают отзыв и реальные навыки</li>
                                </ul>
                            </div>
                        </div>-- >
                    </div>
                </div>
            </section>-->
            
            <section
                class="section index__section index__cooperation"
                v-if="cooperation"
            >
                <div class="container">
                    <div class="index__partner-hl" v-html="cooperation.title"></div>
                    <!--<div class="index__cooperation-desc index__cooperation-desc-text" v-html="cooperation.description"></div>-->
                    <div class="index__cooperation-desc index__cooperation-desc-text">
                        <div class="index__cooperation-desc-col desc-col__left">С 2020 года школа является образовательным партнером Администрации Президента Республики Татарстан, Центра Электронной Торговли "Маркетплейс.Легко», Минпромторга РФ: более 4000 предпринимателей из 15-ти районов республики Татарстан прошли обучение продажам на электронных площадках (маркетплейсах).</div>
                        <div class="index__cooperation-desc-col desc-col__right">С 2021 года iWENGO является оператором образовательных программ национальных проектов "Профессиональный цифровой сертификат" и "Демография" - более 3000 наших  выпускников освоили новые цифровые профессии.
                            <div><a href="/about/programmy-obucheniya-b2g" class="link">УЗНАТЬ ПОДРОБНЕЕ <v-svg name="line" class="svg-line" /></a></div>
                        </div>
                    </div>
                    <div class="index__cooperation-desc" v-if="cooperation.gallery_image.length == 1">
                        <img
                            :data-blink-src="cooperation.gallery_image[0]"  
                        />
                    </div>
                    <div v-else class="index__cooperation-desc">
                        <slider class="index__cooperation-slider"
                                :items="cooperation.gallery_image"
                                :navBetween="true" 
                                :options="cooperationSliderOptions">
                            <template v-slot:slide="{ item }">
                                <img class="index__cooperation__img" :data-blink-src="item" />
                            </template>
                        </slider>
                    </div>
                </div>
            </section>


            <section
                class="section index__section index__teacher index__bottom"
                v-if="teachers && teachers.length > 0"
            >
                <div class="container">
                    <transition name="fade-in" mode="out-in">
                        <slider
                            class="index__teacher-slider"
                            title="Наши преподаватели"
                            key-field="id"
                            :linkText="isTablet ? 'Все' : 'Все преподаватели'"
                            :options="smallCardsOptions"
                            :items="teachers"
                            hrefAll="/teachers"
                            :navBetween="true"
                        >
                            <template v-slot:slide="{ item }">
                                <card-teacher
                                    class="index__teacher-card"
                                    :teacher="item"
                                    :is-small="true"
                                >
                                    <template v-slot:after>
                                        <v-link
                                            v-if="item.course_count > 0"
                                            :href="item.course_list_url"
                                        >
                                            {{ item.course_count }}
                                            {{ pluralForm(item.course_count, ['курс', 'курса', 'курсов']) }}
                                            преподавателя
                                        </v-link>
                                    </template>
                                </card-teacher>
                            </template>
                        </slider>
                    </transition>
                </div>
            </section>

            <!-- используется v-once, не обновляется. чтобы обновлялось, убрать директиву v-once -->
            <!--<section class="section index__section index__catalog">
                <div class="container">
                    <v-list class="index__catalog-list" :items="sections" key-field="title" v-once>
                        <template v-slot:item="{ item: section }">
                            <card-section
                                class="index__catalog-card"
                                :header="section.title"
                                :description="section.description"
                                :image="section.src"
                                :href="section.href"
                            />
                        </template>
                    </v-list>
                </div>
            </section>-->

            <!--<section class="section index__section">
                <div class="container">
                    <div id="frisbuy-widget"></div>
                </div>
            </section>-->

            <!--<section class="section index__section index__vacancy" v-if="mainNews && mainNews.length > 0">
                <div class="container">
                    <div class="index__student-company-hl">Последние новости:</div>
                    <transition name="fade-in" mode="out-in">
                        <v-list class="index__course-list" :items="mainNews" key-field="id" v-once>
                            <template v-slot:item="{ item }">
                                <card-news
                                    class="index__course-card"
                                    :isSmall="isTabletLg"
                                    :news="item"
                                    :isDsrc="true"
                                />
                            </template>
                        </v-list>
                    </transition>
                </div>
            </section>

            <section class="section index__section index__vacancy" v-if="mainVacancies && mainVacancies.length > 0">
                <div class="container">
                    <div class="index__student-company-hl">Наши вакансии:</div>
                    <transition name="fade-in" mode="out-in">
                        <v-list class="index__course-list" :items="mainVacancies" key-field="id" v-once>
                            <template v-slot:item="{ item }">
                                <card-vacancy
                                    class="index__course-card"
                                    :isSmall="isTabletLg"
                                    :vacancy="item"
                                    :isDsrc="true"
                                />
                            </template>
                        </v-list>
                    </transition>
                </div>
            </section>

            <section
                class="section index__section index__teacher-company"
                v-if="teacher_companies && teacher_companies.length > 0"
            >
                <div class="container">
                    <div class="index__teacher-company-hl">Наши преподаватели работали в компаниях:</div>
                    <transition name="fade-in" mode="out-in">
                        <table-company
                            class="index__teacher-company-table"
                            key-field="id"
                            :items="teacher_companies"
                            :rows="teacherCompanyRows"
                            :items-per-row="companiesPerRow"
                        >
                            <template v-slot:item="{ item }">
                                <div class="index__teacher-company-card">
                                    <img
                                        class="lazyload index__teacher-company-card-img"
                                        :data-blink-src="item.image"
                                        :alt="item.name"
                                    />
                                </div>
                            </template>
                        </table-company>
                    </transition>
                    <v-button
                        v-if="isTeacherCompaniesOverflow"
                        class="btn--white index__teacher-company-btn"
                        @click="showAllTeacherCompanies = !showAllTeacherCompanies"
                    >{{ showAllTeacherCompanies ? 'Скрыть' : 'Показать все' }}</v-button>
                </div>
            </section>

            <section
                class="section index__section index__popular"
                v-if="popularCourses && popularCourses.length > 0"
            >
                <div class="container">
                    <transition name="fade-in" mode="out-in">
                        <slider
                            class="index__popular-slider"
                            title="Популярные курсы"
                            :linkText="isTablet ? 'Все курсы' : 'Все учебные курсы'"
                            :options="smallCardsOptions"
                            :items="popularCourses"
                        >
                            <template v-slot:slide="{ item, index }">
                                <card-course class="index__popular-card" :course="item" :seoPosition="index" seoList="Homepage" v-if="!item.isService" />
                                <card-service class="index__popular-card" :service="item" :seoPosition="index" seoList="Homepage" v-else />
                            </template>
                        </slider>
                    </transition>
                </div>
            </section>-->
        </main>
        <ozon-popup/>
    </layout-main>
</template>

<script>
import VButton from '../../components/controls/VButton/VButton.vue';
import VLink from '../../components/controls/VLink/VLink.vue';
import VSvg from '../../components/controls/VSvg/VSvg.vue';
import VList from '../../components/controls/VList/VList.vue';
import Slider from '../../components/controls/slider/slider.vue';
import CourseBanner from '../../components/controls/courseBanner/courseBanner.vue';

import CardBenefit from '../../components/card-benefit/card-benefit.vue';
import CardPartner from '../../components/card-partner/card-partner.vue';
import CardSection from '../../components/card-section/card-section.vue';
import CardSectionVert from '../../components/card-section-vert/card-section-vert.vue';
import CardTeacher from '../../components/card-teacher/card-teacher.vue';
import CardCourse from '../../components/card-course/card-course.vue';
import CardService from '../../components/card-service/card-service.vue';
import CardVacancy from "../../components/card-vacancy/card-vacancy.vue";
import CardNews from '../../components/card-news/card-news.vue';
import CardVideoReview from '../../components/card-video-review/card-video-review.vue';
import TableCompany from '../../components/table-company/table-company.vue';

import OzonPopup from '../../components/ozon-popup/ozon-popup.vue';

import LayoutMixin from '../../mixins/layout';

import documentListImage from '../../../images/inhtml/document-list.jpg';
import consaltingImage from '../../../images/inhtml/consalting.jpg';
import '../../../images/sprite/youtube.svg';
import './Index.css';

export default {
    name: 'index',
    mixins: [LayoutMixin],
    components: {
        VButton,
        VLink,
        VSvg,
        VList,
        Slider,
        CourseBanner,

        CardCourse,
        CardPartner,
        CardSection,
        CardSectionVert,
        CardBenefit,
        CardTeacher,
        CardVideoReview,
        CardService,
        CardVacancy,
        CardNews,

        TableCompany,

        OzonPopup,
    },
    props: [
        'mainCourses',
        'mainServices',
        'mainVacancies',
        'mainNews',
        'partners',
        'cooperation',
        'iwengoNumbers',
        'student_companies',
        'videoReviews',
        'teachers',
        'teacher_companies',
        'popularCourses',
    ],
    data() {
        return {
            showAllTeacherCompanies: false,
            showAllStudentCompanies: false,

            sections: [
                {
                    title: 'Магазин документов',
                    description: 'Покупайте документы, учебники и шаблоны от лидеров рынка Ecommerce',
                    src: documentListImage,
                    href: '/documents',
                },
                {
                    title: 'Консалтинг',
                    description: 'Наши эксперты рады поделиться своими опытом в области электронной коммерции',
                    src: consaltingImage,
                    href: '/consulting',
                },
            ],
            
            cooperationSliderOptions: {
                loop: true,
                slidesPerView: 2,
                spaceBetween: 30,
                observer: true,
                observeParents: true,
                grabCursor: true,
                breakpoints: {
                    1279: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                    },
                    1023: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                    },
                    767: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                },
            },

            smallCardsOptions: {
                slidesPerView: 4,
                spaceBetween: 30,
                observer: true,
                observeParents: true,
                grabCursor: true,
                breakpoints: {
                    1279: {
                        slidesPerView: 3,
                        spaceBetween: 24,
                    },
                    1023: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },
                    767: {
                        slidesPerView: 'auto',
                        spaceBetween: 0,
                    },
                },
            },

            smallCardsVertOptions: {
                slidesPerView: 3,
                spaceBetween: 30,
                observer: true,
                observeParents: true,
                grabCursor: true,
                breakpoints: {
                    1279: {
                        slidesPerView: 3,
                        spaceBetween: 24,
                    },
                    1023: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },
                    767: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                },
            },
        };
    },
    computed: {
        isTablet() {
            return this.$mq.tablet;
        },
        isTabletLg() {
            return this.$mq.tabletLg;
        },
        studentCompanyRows() {
            if (this.showAllStudentCompanies) return 999;
            if (this.$mq.tablet) return 5;
            if (this.$mq.tabletLg) return 4;
            return 3;
        },
        teacherCompanyRows() {
            if (this.showAllTeacherCompanies) return 999;
            if (this.$mq.tablet) return 5;
            if (this.$mq.tabletLg) return 4;
            return 3;
        },
        companiesPerRow() {
            if (this.$mq.tablet) return 3;
            if (this.$mq.tabletLg) return 4;
            return 8;
        },
        maxCompanies() {
            if (this.$mq.tablet) return 15;
            if (this.$mq.tabletLg) return 16;
            return 24;
        },
        isTeacherCompaniesOverflow() {
            return this.maxCompanies < this.teacher_companies.length;
        },
        isStudentCompaniesOverflow() {
            return this.maxCompanies < this.student_companies.length;
        },
    }
};
</script>
