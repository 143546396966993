import { render, staticRenderFns } from "./info-group.vue?vue&type=template&id=7dc93878&"
import script from "./info-group.vue?vue&type=script&lang=js&"
export * from "./info-group.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev_iwengo_ecom/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7dc93878')) {
      api.createRecord('7dc93878', component.options)
    } else {
      api.reload('7dc93878', component.options)
    }
    module.hot.accept("./info-group.vue?vue&type=template&id=7dc93878&", function () {
      api.rerender('7dc93878', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/info-group/info-group.vue"
export default component.exports