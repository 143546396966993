var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-vacancy",
      class: { "card-vacancy--small": _vm.isSmall }
    },
    [
      _vm.isSmall
        ? _c(
            "div",
            { staticClass: "card-vacancy__tags" },
            [
              _vm.vacancy.is_bestseller
                ? _c(
                    "info-tag",
                    {
                      staticClass: "card-vacancy__tag",
                      attrs: { type: "pink" }
                    },
                    [_vm._v("\n            Хит\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.vacancy.is_new
                ? _c(
                    "info-tag",
                    {
                      staticClass: "card-vacancy__tag",
                      attrs: { type: "blue" }
                    },
                    [_vm._v("\n            Новинка\n        ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "card-vacancy__detail",
          attrs: { href: _vm.vacancy.detail_url }
        },
        [
          _c("img", {
            staticClass: "lazyload card-vacancy__detail-img",
            attrs: { "data-blink-src": _vm.cardImage, alt: "" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-vacancy__body" },
        [
          _c(
            "div",
            { staticClass: "card-vacancy__top" },
            [
              _c("info-panel"),
              _vm._v(" "),
              _c("info-panel", {
                staticClass: "card-vacancy__panel",
                attrs: {
                  reviewsCount: _vm.vacancy.total_review_count,
                  rating: 0
                },
                scopedSlots: _vm._u(
                  [
                    !_vm.isSmall
                      ? {
                          key: "tags",
                          fn: function(ref) {
                            var tagClass = ref.tagClass
                            return [
                              _vm.vacancy.is_bestseller
                                ? _c(
                                    "info-tag",
                                    {
                                      class: tagClass,
                                      attrs: { type: "pink" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Хит\n                    "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.vacancy.is_new
                                ? _c(
                                    "info-tag",
                                    {
                                      class: tagClass,
                                      attrs: { type: "blue" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Новинка\n                    "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      : null
                  ],
                  null,
                  true
                )
              }),
              _vm._v(" "),
              !_vm.isSmall
                ? _c("info-group", {
                    staticClass: "card-vacancy__info",
                    attrs: { items: _vm.infoGroup }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-link",
            {
              staticClass: "card-vacancy__link",
              attrs: { href: _vm.vacancy.detail_url }
            },
            [
              _c(
                "v-clamp",
                { attrs: { autoresize: "", "max-lines": _vm.maxLines } },
                [_vm._v(_vm._s(_vm.vacancy.name))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isSmall
            ? _c("info-group", {
                staticClass: "card-vacancy__info",
                attrs: { items: _vm.infoGroup }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isDsrc
            ? _c("info-html", {
                staticClass: "line-clamp card-vacancy__info ",
                domProps: { innerHTML: _vm._s(_vm.vacancy.short_description) }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "price-panel" },
            [
              _c(
                "v-button",
                {
                  staticClass: "price-panel__btn",
                  attrs: { href: _vm.vacancy.detail_url }
                },
                [_vm._v("Подробнее")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }