var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-course",
      class: { "card-course--small": _vm.isSmall }
    },
    [
      _vm.isSmall
        ? _c(
            "div",
            { staticClass: "card-course__tags" },
            [
              _vm.service.is_bestseller
                ? _c(
                    "info-tag",
                    {
                      staticClass: "card-course__tag",
                      attrs: { type: "pink" }
                    },
                    [_vm._v("\n            Хит\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.service.is_new
                ? _c(
                    "info-tag",
                    {
                      staticClass: "card-course__tag",
                      attrs: { type: "blue" }
                    },
                    [_vm._v("\n            Новинка\n        ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "card-course__detail",
          attrs: { href: _vm.service.detail_url },
          on: {
            click: function($event) {
              return _vm.sendGtag($event)
            }
          }
        },
        [
          _c("img", {
            staticClass: "lazyload card-course__detail-img",
            attrs: { "data-blink-src": _vm.cardImage, alt: "" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-course__body" },
        [
          _c(
            "div",
            { staticClass: "card-course__top" },
            [
              _c("info-panel"),
              _vm._v(" "),
              _c("info-panel", {
                staticClass: "card-course__panel",
                attrs: {
                  reviewsCount: _vm.service.total_review_count,
                  rating: 0
                },
                scopedSlots: _vm._u(
                  [
                    !_vm.isSmall
                      ? {
                          key: "tags",
                          fn: function(ref) {
                            var tagClass = ref.tagClass
                            return [
                              _vm.service.is_bestseller
                                ? _c(
                                    "info-tag",
                                    {
                                      class: tagClass,
                                      attrs: { type: "pink" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Хит\n                    "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.service.is_new
                                ? _c(
                                    "info-tag",
                                    {
                                      class: tagClass,
                                      attrs: { type: "blue" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Новинка\n                    "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      : null
                  ],
                  null,
                  true
                )
              }),
              _vm._v(" "),
              !_vm.isSmall
                ? _c("info-group", {
                    staticClass: "card-course__info",
                    attrs: { items: _vm.infoGroup }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-link",
            {
              staticClass: "card-course__link",
              attrs: { href: _vm.service.detail_url },
              on: {
                click: function($event) {
                  return _vm.sendGtag($event)
                }
              }
            },
            [
              _c(
                "v-clamp",
                { attrs: { autoresize: "", "max-lines": _vm.maxLines } },
                [_vm._v(_vm._s(_vm.service.name))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isSmall
            ? _c("info-group", {
                staticClass: "card-course__info",
                attrs: { items: _vm.infoGroup }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isDsrc
            ? _c("info-html", {
                staticClass: "line-clamp card-course__info ",
                domProps: { innerHTML: _vm._s(_vm.service.short_description) }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("price-panel", {
            staticClass: "card-course__price",
            attrs: {
              price: _vm.service.price,
              oldPrice: _vm.service.price_old
            },
            scopedSlots: _vm._u([
              {
                key: "btns",
                fn: function(ref) {
                  var btnClass = ref.btnClass
                  return [
                    _c(
                      "v-button",
                      {
                        class: btnClass,
                        attrs: { href: _vm.service.detail_url }
                      },
                      [_vm._v("Подробнее")]
                    ),
                    _vm._v(" "),
                    !_vm.isSmall
                      ? _c(
                          "v-button",
                          {
                            class: ["btn--wishlist", btnClass],
                            attrs: { "icon-name": _vm.wishlistIcon },
                            on: {
                              click: function($event) {
                                return _vm.wishlist_ToggleWishlist({
                                  type: "service",
                                  id: _vm.service.id
                                })
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.isSmall || _vm.isDesktopLg
                                    ? ""
                                    : _vm.wishlistBtnText
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }