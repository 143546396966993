<template>
    <div class="card-teacher" :class="{ 'card-teacher--small': isSmall }">
        <a class="card-teacher__preview" :href="teacher.detail_url">
            <img
                class="lazyload card-teacher__img"
                v-if="teacher.image"
                :data-blink-src="teacher.image"
                alt
            />
            <div class="card-teacher__img card-teacher__img--empty" v-else>
                <v-svg name="user" />
            </div>
        </a>
        <div class="card-teacher__body">
            <div class="card-teacher__hl">{{ teacher.name }}</div>
            <slot name="before" />
            <a class="card-teacher__body-link" :href="teacher.detail_url">
                <v-clamp
                    class="card-teacher__desc"
                    autoresize
                    :maxLines="maxLines"
                >
                    {{ teacher.description }}
                    <template v-slot:after="{ toggle, clamped, expanded }">
                        <v-link
                            tag="button"
                            class="card-teacher__desc-link"
                            v-if="!isSmall && (clamped || expanded)"
                            @click="toggle"
                        >{{ clamped ? 'Показать всё' : 'Скрыть' }}</v-link>
                    </template>
                </v-clamp>
            </a>
            <slot name="after" />
        </div>
    </div>
</template>

<script>
import VLink from '../controls/VLink/VLink.vue';
import VClamp from 'vue-clamp';
import VSvg from '../controls/VSvg/VSvg.vue';

import '../../../images/sprite/user.svg';
import './card-teacher.css';

export default {
    name: 'card-teacher',
    props: {
        teacher: {
            type: Object,
            default() {
                return {};
            },
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        VClamp,
        VSvg,
        VLink,
    },
    computed: {
        maxLines() {
            return this.isSmall ? 6 : 3;
        },
    },
};
</script>
