var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slider" },
    [
      _c(
        "div",
        { class: ["slider__header", _vm.title || _vm.hrefAll ? "" : "mb-0"] },
        [
          _c("div", { staticClass: "slider__title-block" }, [
            _vm.title
              ? _c("div", { staticClass: "slider__title" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hrefAll
              ? _c(
                  "a",
                  {
                    staticClass: "link slider__link",
                    attrs: { href: _vm.hrefAll }
                  },
                  [_vm._v(_vm._s(_vm.linkText))]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "slider__addition" },
            [_vm._t("addition")],
            2
          ),
          _vm._v(" "),
          _vm.showNavigation
            ? _c(
                "div",
                {
                  class: _vm.navBetween
                    ? "slider__navigation-between"
                    : "slider__navigation"
                },
                [
                  _c("v-button", {
                    ref: "prevBtn",
                    staticClass:
                      "btn--arrow btn--arrow-left slider__navigation-btn",
                    attrs: { iconName: "line" }
                  }),
                  _vm._v(" "),
                  _c("v-button", {
                    ref: "nextBtn",
                    staticClass:
                      "btn--arrow btn--arrow-right slider__navigation-btn",
                    attrs: { iconName: "line" }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.swiperOption
        ? _c(
            "swiper",
            {
              ref: "slider",
              staticClass: "slider__slider",
              attrs: { options: _vm.swiperOption }
            },
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "swiper-slide",
                {
                  key: item[_vm.keyField] || index,
                  staticClass: "slider__slide",
                  class: _vm.twoCol ? "slider__slide-two" : ""
                },
                [_vm._t("slide", null, { item: item, index: index })],
                2
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }