<template>
    <component class="info-html" :is="component || 'div'" />
</template>

<script>
import './info-html.css';

export default {
    name: 'info-html',
    props: ['component'],
};
</script>
