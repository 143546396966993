<template>
    <div class="card-section-vert">
        <a class="card-section-vert__preview" :href="href">
            <img class="lazyload card-section-vert__img" :data-blink-src="image" alt />
        </a>
        <div class="card-section-vert__body">
            <div class="card-section-vert__hl">
                {{ header }}
            </div>
            <v-clamp class="card-section-vert__desc" autoresize :maxLines="maxLines">{{ description }}</v-clamp>
            <div class="card-section-vert__link">
                <v-link :href="href">Подробнее</v-link>
            </div>
        </div>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import VLink from '../controls/VLink/VLink.vue';
import './card-section-vert.css';

export default {
    name: 'card-section-vert',
    props: {
        href: String,
        image: {},
        header: String,
        description: String,
    },
    components: {
        VClamp,
        VLink,
    },
    computed: {
        maxLines() {
            return this.$mq.desktopLg ? 3 : 2;
        },
    },
};
</script>
