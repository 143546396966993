var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-teacher",
      class: { "card-teacher--small": _vm.isSmall }
    },
    [
      _c(
        "a",
        {
          staticClass: "card-teacher__preview",
          attrs: { href: _vm.teacher.detail_url }
        },
        [
          _vm.teacher.image
            ? _c("img", {
                staticClass: "lazyload card-teacher__img",
                attrs: { "data-blink-src": _vm.teacher.image, alt: "" }
              })
            : _c(
                "div",
                { staticClass: "card-teacher__img card-teacher__img--empty" },
                [_c("v-svg", { attrs: { name: "user" } })],
                1
              )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-teacher__body" },
        [
          _c("div", { staticClass: "card-teacher__hl" }, [
            _vm._v(_vm._s(_vm.teacher.name))
          ]),
          _vm._v(" "),
          _vm._t("before"),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "card-teacher__body-link",
              attrs: { href: _vm.teacher.detail_url }
            },
            [
              _c(
                "v-clamp",
                {
                  staticClass: "card-teacher__desc",
                  attrs: { autoresize: "", maxLines: _vm.maxLines },
                  scopedSlots: _vm._u([
                    {
                      key: "after",
                      fn: function(ref) {
                        var toggle = ref.toggle
                        var clamped = ref.clamped
                        var expanded = ref.expanded
                        return [
                          !_vm.isSmall && (clamped || expanded)
                            ? _c(
                                "v-link",
                                {
                                  staticClass: "card-teacher__desc-link",
                                  attrs: { tag: "button" },
                                  on: { click: toggle }
                                },
                                [
                                  _vm._v(
                                    _vm._s(clamped ? "Показать всё" : "Скрыть")
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.teacher.description) +
                      "\n                "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("after")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }