<template>
    <div class="info-group">
        <ul class="info-group__list">
            <li :key="index" v-for="(item, index) in items" class="text-sm info-group__item">
                <slot name="item" :item="item" :index="index" itemClass="info-group__item">
                    {{ item }}
                </slot>
            </li>
        </ul>
    </div>
</template>

<script>
import './info-group.css';

export default {
    name: 'info-group',
    props: {
        items: { type: Array, default: [] },
    },
};
</script>
