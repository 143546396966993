<template>
    <div class="card-course" :class="{ 'card-course--small': isSmall }">
        <div class="card-course__tags" v-if="isSmall">
            <info-tag class="card-course__tag" v-if="service.is_bestseller" type="pink">
                Хит
            </info-tag>
            <info-tag class="card-course__tag" v-if="service.is_new" type="blue">
                Новинка
            </info-tag>
        </div>
        <a class="card-course__detail" :href="service.detail_url" @click="sendGtag($event)">
            <!--   course.image_on_main - картинка для главной   -->
            <!--   videoOnMain - видео для главной (может отсутствовать)  -->
            <img class="lazyload card-course__detail-img" :data-blink-src="cardImage" alt />
        </a>
        <div class="card-course__body">
            <div class="card-course__top">
                <info-panel></info-panel>
                <info-panel class="card-course__panel" :reviewsCount="service.total_review_count" :rating="0">
                    <template v-if="!isSmall" v-slot:tags="{ tagClass }">
                        <info-tag :class="tagClass" v-if="service.is_bestseller" type="pink">
                            Хит
                        </info-tag>
                        <info-tag :class="tagClass" v-if="service.is_new" type="blue">
                            Новинка
                        </info-tag>
                    </template>
                </info-panel>
                <info-group class="card-course__info" :items="infoGroup" v-if="!isSmall" />
            </div>
            <v-link class="card-course__link" :href="service.detail_url" @click="sendGtag($event)">
                <v-clamp autoresize :max-lines="maxLines">{{ service.name }}</v-clamp>
            </v-link>
            <info-group class="card-course__info" :items="infoGroup" v-if="isSmall" />
            <info-html  v-if="isDsrc" class="line-clamp card-course__info " v-html="service.short_description">
            </info-html>
            <price-panel
                class="card-course__price"
                :price="service.price"
                :oldPrice="service.price_old"
            >
                <template v-slot:btns="{ btnClass }">
                    <v-button :class="btnClass" :href="service.detail_url">Подробнее</v-button>
                    <v-button
                        v-if="!isSmall"
                        :class="['btn--wishlist', btnClass]"
                        :icon-name="wishlistIcon"
                        @click="wishlist_ToggleWishlist({ type: 'service', id: service.id })"
                    >
                        {{ isSmall || isDesktopLg ? '' : wishlistBtnText }}
                    </v-button>
                </template>
            </price-panel>
        </div>
    </div>
</template>

<script>
import VButton from '../controls/VButton/VButton.vue';
import VLink from '../controls/VLink/VLink.vue';
import VClamp from 'vue-clamp';

import InfoHtml from '../info-html/info-html.vue';
import InfoTag from '../info-tag/info-tag.vue';
import InfoLabel from '../info-label/info-label.vue';
import InfoGroup from '../info-group/info-group.vue';
import InfoPanel from '../info-panel/info-panel.vue';
import PricePanel from '../PricePanel/PricePanel.vue';

import CourseMixin from '../../mixins/course';
import WishlistMixin from '../../mixins/wishlist.js';

import './card-service.css';
import Seo from "../../../scripts/seo";

export default {
    name: 'card-service',
    mixins: [CourseMixin, WishlistMixin()],
    props: {
        service: Object,
        isSmall: {
            type: Boolean,
            default: true,
        },
        isDsrc: {
            type: Boolean,
            default: false,
        },
        seoList: {
            type: String,
            default: 'Category',
        },
        seoPosition: {
            type: Number,
            default: 0,
        },
    },
    components: {
        VButton,
        VLink,
        VClamp,

        PricePanel,
        InfoHtml,
        InfoTag,
        InfoLabel,
        InfoPanel,
        InfoGroup,
    },
    computed: {
        isDesktopLg() {
            return this.$mq.desktopLg;
        },
        wishlistIcon() {
            return this.wishlist_InWishlist('service', this.service.id) ? 'heart' : 'wishlist';
        },
        wishlistBtnText() {
            return this.wishlist_InWishlist('service', this.service.id) ? 'В избранном' : 'В избранное';
        },
        infoGroup() {
            return [
            ];
        },
        maxLines() {
            return this.$mq.tablet ? 3 : 2;
        },
        videoOnMain() {
            if (this.service.gallery_video.length > 0) {
                return this.service.gallery_video[0].url;
            }
        },
        cardImage() {
            return this.isSmall ? this.service.image : this.service.image_on_main;
        },
    },
    methods: {
        sendGtag(e) {
            if(this.seoList === '') return;

            Seo.click(this.$gtag, this.service, 'service', this.seoPosition + 1, this.seoList);
        }
    }
};
</script>
