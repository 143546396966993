var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-section-vert" }, [
    _c(
      "a",
      { staticClass: "card-section-vert__preview", attrs: { href: _vm.href } },
      [
        _c("img", {
          staticClass: "lazyload card-section-vert__img",
          attrs: { "data-blink-src": _vm.image, alt: "" }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-section-vert__body" },
      [
        _c("div", { staticClass: "card-section-vert__hl" }, [
          _vm._v("\n            " + _vm._s(_vm.header) + "\n        ")
        ]),
        _vm._v(" "),
        _c(
          "v-clamp",
          {
            staticClass: "card-section-vert__desc",
            attrs: { autoresize: "", maxLines: _vm.maxLines }
          },
          [_vm._v(_vm._s(_vm.description))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-section-vert__link" },
          [_c("v-link", { attrs: { href: _vm.href } }, [_vm._v("Подробнее")])],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }