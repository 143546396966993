<template>
    <div class="info-panel">
        <slot name="tags" tagClass="info-panel__tag" />
        <v-link class="info-panel__link" :href="(reviewLink !== '') ? reviewLink : '#reviews'" :tag="(reviewLink !== '')?'a':'span'">
        <template v-if="reviewsCount > 0">
            <span class="info-panel__review-count">{{ reviewsCount }}</span>
            <v-svg class="info-panel__review-icon" name="review" />
        </template>
        <rating v-if="rating" class="info-panel__rating" :value="rating" readonly>
            <template v-slot:inactiveLabel>
                <v-svg class="rating" name="star-stroke" />
            </template>
            <template v-slot:activeLabel>
                <v-svg class="rating" name="star" />
            </template>
        </rating>
         </v-link>
        <div class="info-panel__downloads">
            <slot name="downloads" />
        </div>
    </div>
</template>

<script>
import VSvg from '../controls/VSvg/VSvg.vue';
import VLink from '../../components/controls/VLink/VLink.vue';
import rating from '../controls/rating/rating.vue';

import '../../../images/sprite/review.svg';
import '../../../images/sprite/star-stroke.svg';
import '../../../images/sprite/star.svg';
import './info-panel.css';

export default {
    name: 'info-panel',
    components: { rating, VSvg, VLink },
    props: {
        reviewsCount: {
            type: Number,
            default: 0,
        },
        rating: {
            type: Number,
            default: 0,
        },
        reviewLink: {
            type: String,
            default: '',
        }
    },
};
</script>
