<template>
    <div class="card-benefit">
        <div class="card-benefit__header">{{ header }}</div>
        <div class="card-benefit__desc">{{ description }}</div>
    </div>
</template>

<script>
import './card-benefit.css';

export default {
    name: 'card-benefit',
    props: {
        header: [String, Number],
        description: String,
    },
};
</script>
