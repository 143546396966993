<template>
    <div class="info-tag" :class="{ [`info-tag--${type}`]: type }">
        <slot />
    </div>
</template>

<script>
import './info-tag.css';

export default {
    name: 'info-tag',
    props: {
        type: { type: String },
    },
};
</script>
