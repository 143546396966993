var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info-group" }, [
    _c(
      "ul",
      { staticClass: "info-group__list" },
      _vm._l(_vm.items, function(item, index) {
        return _c(
          "li",
          { key: index, staticClass: "text-sm info-group__item" },
          [
            _vm._t(
              "item",
              [_vm._v("\n                " + _vm._s(item) + "\n            ")],
              { item: item, index: index, itemClass: "info-group__item" }
            )
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }