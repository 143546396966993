var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-partner" },
    [
      _c("img", {
        staticClass: "lazyload card-partner__img",
        attrs: { "data-blink-src": _vm.image }
      }),
      _vm._v(" "),
      _c(
        "v-clamp",
        {
          staticClass: "card-partner__desc",
          attrs: { autoresize: "", maxLines: _vm.maxLines }
        },
        [_vm._v(_vm._s(_vm.description))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }