<template>
    <div class="card-video-review">
        <div class="card-video-review__head" :title="title">
            <v-clamp tag="p" class="text-review card-video-review__head-text" :max-lines="3" autoresize>
                <template v-slot:before
                    ><v-svg class="card-video-review__head-quote" name="quote"/></template
                >{{ title
                }}<template v-slot:after
                    >&nbsp;<v-svg class="card-video-review__head-quote" name="quote"
                /></template>
            </v-clamp>
        </div>
        <div class="card-video-review__description">
            <!--<div v-if="student">{{ student.last_name }} {{ student.name }}</div>-->
            <div v-if="product && product.length > 0" class="card-video-review__description-course"><a :href="getProductHref(product)" v-html="getProductHtml(product)"></a></div>
        </div>
        <div class="card-video-review__body">
            <iframe
                class="lazyload card-video-review__iframe"
                :data-src="src"
                frameborder="0"
                rel="nofollow"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
        </div>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import VSvg from '../controls/VSvg/VSvg.vue';

import '../../../images/sprite/quote.svg';
import './card-video-review.css';

/**
 * Класс для видео ревью
 */
export default {
    name: 'card-video-review',
    components: {
        VSvg,
        VClamp,
    },
    props: {
        title: String,
        src: String,
        student: String,
        product: Array,
    },
    methods: {
        getProductHref(products) {
            for (let p of products) {
                // бэк отдаёт элемент равный null, если условие course.active == true не выполнено
                if (p.course !== null)
                    return p.course.detail_url;
            }
            return '';
        },
        getProductHtml(products) {
            for (let p of products) {
                // бэк отдаёт элемент равный null, если условие course.active == true не выполнено
                if (p.course !== null)
                    if (p.course.name !== null)
                        return 'Курс "' + p.course.name + '"';
            }
            return '';
        }
    }
};
</script>
