var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-video-review" }, [
    _c(
      "div",
      { staticClass: "card-video-review__head", attrs: { title: _vm.title } },
      [
        _c(
          "v-clamp",
          {
            staticClass: "text-review card-video-review__head-text",
            attrs: { tag: "p", "max-lines": 3, autoresize: "" },
            scopedSlots: _vm._u([
              {
                key: "before",
                fn: function() {
                  return [
                    _c("v-svg", {
                      staticClass: "card-video-review__head-quote",
                      attrs: { name: "quote" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "after",
                fn: function() {
                  return [
                    _vm._v(" "),
                    _c("v-svg", {
                      staticClass: "card-video-review__head-quote",
                      attrs: { name: "quote" }
                    })
                  ]
                },
                proxy: true
              }
            ])
          },
          [_vm._v(_vm._s(_vm.title))]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-video-review__description" }, [
      _vm.product && _vm.product.length > 0
        ? _c("div", { staticClass: "card-video-review__description-course" }, [
            _c("a", {
              attrs: { href: _vm.getProductHref(_vm.product) },
              domProps: { innerHTML: _vm._s(_vm.getProductHtml(_vm.product)) }
            })
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-video-review__body" }, [
      _c("iframe", {
        staticClass: "lazyload card-video-review__iframe",
        attrs: {
          "data-src": _vm.src,
          frameborder: "0",
          rel: "nofollow",
          allow:
            "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: ""
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }