/**
 * Миксин хэлпер методов для получения цены спана или курса
 */
export default {
    methods: {
        /**
         * Получить текущую цену курса (если указат span, то цену спана)
         * @param {*} span
         * @param {*} course
         */
        price(span, course, tariff = '') {
            if (tariff !== '') return tariff;
            if (span && span.price_custom) return span.price;
            return course.price;
        },

        /**
         * Получить старую цену курса (если указат span, то цену спана)
         * @param {*} span
         * @param {*} course
         */
        priceOld(span, course, tariff = '') {
            if (tariff !== '') return tariff;
            if (span && span.price_custom) return span.price_old;
            return course.price_old;
        },
    },
};
