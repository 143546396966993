var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-panel" },
    [
      _vm._t("tags", null, { tagClass: "info-panel__tag" }),
      _vm._v(" "),
      _c(
        "v-link",
        {
          staticClass: "info-panel__link",
          attrs: {
            href: _vm.reviewLink !== "" ? _vm.reviewLink : "#reviews",
            tag: _vm.reviewLink !== "" ? "a" : "span"
          }
        },
        [
          _vm.reviewsCount > 0
            ? [
                _c("span", { staticClass: "info-panel__review-count" }, [
                  _vm._v(_vm._s(_vm.reviewsCount))
                ]),
                _vm._v(" "),
                _c("v-svg", {
                  staticClass: "info-panel__review-icon",
                  attrs: { name: "review" }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.rating
            ? _c("rating", {
                staticClass: "info-panel__rating",
                attrs: { value: _vm.rating, readonly: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "inactiveLabel",
                      fn: function() {
                        return [
                          _c("v-svg", {
                            staticClass: "rating",
                            attrs: { name: "star-stroke" }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "activeLabel",
                      fn: function() {
                        return [
                          _c("v-svg", {
                            staticClass: "rating",
                            attrs: { name: "star" }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  4268377975
                )
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "info-panel__downloads" },
        [_vm._t("downloads")],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }