<template>
    <div class="card-vacancy" :class="{ 'card-vacancy--small': isSmall }">
        <div class="card-vacancy__tags" v-if="isSmall">
            <info-tag class="card-vacancy__tag" v-if="news.is_bestseller" type="pink">
                Хит
            </info-tag>
            <info-tag class="card-vacancy__tag" v-if="news.is_new" type="blue">
                Новинка
            </info-tag>
        </div>
        <a class="card-vacancy__detail" :href="news.detail_url">
            <!--   course.image_on_main - картинка для главной   -->
            <!--   videoOnMain - видео для главной (может отсутствовать)  -->
            <img class="lazyload card-vacancy__detail-img" :data-blink-src="cardImage" alt />
        </a>
        <div class="card-vacancy__body">
            <div class="card-vacancy__top">
                <info-panel></info-panel>
                <info-panel class="card-vacancy__panel" :reviewsCount="news.total_review_count" :rating="0">
                    <template v-if="!isSmall" v-slot:tags="{ tagClass }">
                        <info-tag :class="tagClass" v-if="news.is_bestseller" type="pink">
                            Хит
                        </info-tag>
                        <info-tag :class="tagClass" v-if="news.is_new" type="blue">
                            Новинка
                        </info-tag>
                    </template>
                </info-panel>
                <info-group class="card-vacancy__info" :items="infoGroup" v-if="!isSmall" />
            </div>
            <v-link class="card-vacancy__link" :href="news.detail_url">
                <v-clamp autoresize :max-lines="maxLines">{{ news.name }}</v-clamp>
            </v-link>
            <info-group class="card-vacancy__info" :items="infoGroup" v-if="isSmall" />
            <info-html  v-if="isDsrc" class="line-clamp card-vacancy__info " v-html="news.short_description">
            </info-html>
            <div class="price-panel">
                <v-button class="price-panel__btn" :href="news.detail_url">Подробнее</v-button>
            </div>
        </div>
    </div>
</template>

<script>
import VButton from '../controls/VButton/VButton.vue';
import VLink from '../controls/VLink/VLink.vue';
import VClamp from 'vue-clamp';

import InfoHtml from '../info-html/info-html.vue';
import InfoTag from '../info-tag/info-tag.vue';
import InfoLabel from '../info-label/info-label.vue';
import InfoGroup from '../info-group/info-group.vue';
import InfoPanel from '../info-panel/info-panel.vue';
import PricePanel from '../PricePanel/PricePanel.vue';

import CourseMixin from '../../mixins/course';
import WishlistMixin from '../../mixins/wishlist.js';

import './card-news.css';

export default {
    name: 'card-news',
    mixins: [CourseMixin, WishlistMixin()],
    props: {
        news: Object,
        isSmall: {
            type: Boolean,
            default: true,
        },
        isDsrc: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        VButton,
        VLink,
        VClamp,

        PricePanel,
        InfoHtml,
        InfoTag,
        InfoLabel,
        InfoPanel,
        InfoGroup,
    },
    computed: {
        isDesktopLg() {
            return this.$mq.desktopLg;
        },
        infoGroup() {
            return [
            ];
        },
        maxLines() {
            return this.$mq.tablet ? 3 : 2;
        },
        videoOnMain() {
            if (this.news.gallery_video.length > 0) {
                return this.news.gallery_video[0].url;
            }
        },
        cardImage() {
            return this.isSmall ? this.news.image : this.news.image_on_main;
        },
    },
};
</script>
