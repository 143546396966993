var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "ratingEl",
      staticClass: "vue-stars",
      class: { readonly: _vm.readonly, notouch: _vm.notouch },
      style: _vm.mapCssProps
    },
    [
      _c("input", {
        attrs: {
          id: "vue-stars-" + _vm._uid + "-0",
          type: "radio",
          name: _vm.name,
          value: "0"
        },
        domProps: { checked: _vm.valueInt === 0 }
      }),
      _vm._v(" "),
      _vm._l(_vm.max, function(x) {
        return _c("span", { key: x }, [
          _c("label", { attrs: { for: "vue-stars-" + _vm._uid + "-" + x } }, [
            _vm.showHalfStar(x)
              ? _c(
                  "span",
                  { staticClass: "position-relative" },
                  [
                    _vm._t("activeLabel", [
                      _vm._v(_vm._s(_vm.getActiveLabel(x)))
                    ]),
                    _vm._v(" "),
                    _vm._t("inactiveLabel", [
                      _vm._v(_vm._s(_vm.getInactiveLabel(x)))
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.valueInt >= x && !_vm.showHalfStar(x)
              ? _c(
                  "span",
                  [
                    _vm._t("activeLabel", [
                      _vm._v(_vm._s(_vm.getActiveLabel(x)))
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.valueInt < x && !_vm.showHalfStar(x)
              ? _c(
                  "span",
                  [
                    _vm._t("inactiveLabel", [
                      _vm._v(_vm._s(_vm.getInactiveLabel(x)))
                    ])
                  ],
                  2
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.readonly
            ? _c("input", {
                ref: "input",
                refInFor: true,
                attrs: {
                  id: "vue-stars-" + _vm._uid + "-" + x,
                  type: "radio",
                  name: _vm.name
                },
                domProps: { checked: _vm.valueInt === x, value: x },
                on: {
                  change: function($event) {
                    return _vm.updateInput(x)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.readonly
            ? _c("input", {
                ref: "input",
                refInFor: true,
                attrs: {
                  id: "vue-stars-" + _vm._uid + "-" + x,
                  type: "radio",
                  name: _vm.name,
                  disabled: ""
                },
                domProps: { checked: _vm.valueInt === x, value: x }
              })
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }