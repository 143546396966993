<template>
    <div class="price-panel">
        <div class="price-panel__prices">
            <div
                class="price-panel__price price-panel__price--old"
                v-if="showOld && oldPrice > 0 && oldPrice !== price"
            >
                {{ preparePrice(oldPrice) }}
                <span>&#8381;</span>
            </div>
            <div class="price-panel__price" v-if="price > 0">
                {{ preparePrice(price) }}
                <span>&#8381;</span>
            </div>
            <div class="price-panel__price" v-else-if="price < 0">
                Цена по запросу
            </div>
        </div>
        <!--<div id="credit-info" v-if="price"></div>-->
        <!--<div class="price-panel__credit" v-if="price && isCourseDetail && showCredit">
            <v-svg class="price-panel__payment-icon" name="tinkoff-square" />
            Купить от {{ preparePrice(price/19) }} ₽ / мес
        </div>-->
        <div class="price-panel__btns">
            <slot name="btns" btnClass="price-panel__btn"></slot>
        </div>
    </div>
</template>

<script>
import VSvg from '../controls/VSvg/VSvg.vue';
import './PricePanel.css';
import CheckoutCreditUI from '../../plugins/СheckoutCreditUI';

export default {
    name: 'price-panel',
    components: {
        VSvg,
    },
    props: {
        price: {
            type: [String, Number],
        },
        oldPrice: {
            type: [String, Number],
        },
        showOld: {
            type: Boolean,
            default: true
        },
        isCourseDetail: {
            type: Boolean,
            default: false
        },
        showCredit: {
            type: Boolean,
            default: false
        },
    }/*,
    methods: {
        showCredit() {
            const isEmpty = document.getElementById('credit-info').innerHTML === "";

            if(this.isCourseDetail && isEmpty && this.price !== null && this.price !== 0 && this.price !== -1) {
                const $checkoutCreditUI = CheckoutCreditUI({
                    shopId: '179685',
                    sum: this.price
                });

                $checkoutCreditUI.update();

                const checkoutCreditText = $checkoutCreditUI({
                    type: 'info',
                    domSelector: '#credit-info'
                });
            }
        },
    },
    mounted() {
        this.showCredit();
    }*/
};
</script>
