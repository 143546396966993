var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    _vm._g(
      _vm._b({ staticClass: "table-company" }, "table", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _c(
        "colgroup",
        _vm._l(_vm.itemsPerRow, function(i) {
          return _c("col", {
            key: i,
            attrs: { width: 100 / _vm.itemsPerRow + "%" }
          })
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "tbody",
        { staticClass: "table-company__body" },
        _vm._l(_vm.computedRows, function(row, index) {
          return _c(
            "tr",
            { key: index, staticClass: "table-company__row" },
            _vm._l(row, function(item, index) {
              return _c(
                "td",
                {
                  key: item[_vm.keyField] || index,
                  staticClass: "table-company__row-cell"
                },
                [_vm._t("item", [_vm._v(_vm._s(item))], { item: item })],
                2
              )
            }),
            0
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }