<template>
    <table class="table-company" v-bind="$attrs" v-on="$listeners">
        <colgroup>
            <col v-for="i in itemsPerRow" :key="i" :width="`${100 / itemsPerRow}%`" />
        </colgroup>
        <tbody class="table-company__body">
            <tr class="table-company__row" v-for="(row, index) in computedRows" :key="index">
                <td class="table-company__row-cell" v-for="(item, index) in row" :key="item[keyField] || index">
                    <slot name="item" :item="item">{{ item }}</slot>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import './table-company.css';

export default {
    name: 'table-company',
    inheritAttrs: false,
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        rows: {
            type: Number,
            default: 1,
            validator(value) {
                return value > 0;
            },
        },
        itemsPerRow: {
            type: Number,
            default: 3,
            validator(value) {
                return value > 0;
            },
        },
        keyField: {
            type: String,
        },
    },
    computed: {
        computedRows() {
            const rows = [];
            let currentRow = [];

            for (let i = 1; i < this.items.length + 1; i++) {
                currentRow.push(this.items[i - 1]);
                if (i % this.itemsPerRow === 0) {
                    if (rows.length === this.rows - 1) break;
                    rows.push(currentRow);
                    currentRow = [];
                }
            }
            rows.push(currentRow);
            return rows;
        },
    },
};
</script>
