var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-main",
    { ref: "main", staticClass: "index" },
    [
      _c("main", { staticClass: "wrap-main index__main" }, [
        _c("section", { staticClass: "section index__section index__course" }, [
          _c("div", { staticClass: "container" }, [_vm._m(0)], 1)
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "section index__section" }, [
          _c("div", { staticClass: "container text-center" }, [
            _c(
              "a",
              { staticClass: "btn btn--white", attrs: { href: "/courses" } },
              [
                _vm._v("Смотреть все программы обучения "),
                _c("v-svg", {
                  staticClass: "svg-line",
                  attrs: { name: "line" }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "section index__section index__banner-e" },
          [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("CourseBanner", {
                  attrs: { banners: _vm.layout_CourseBanners, homePage: true }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _vm.mainServices && _vm.mainServices.length > 0
          ? _c(
              "section",
              {
                staticClass:
                  "section index__section index__teacher index__services"
              },
              [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade-in", mode: "out-in" } },
                      [
                        _c("slider", {
                          staticClass: "index__teacher-slider",
                          attrs: {
                            title: "Наши услуги",
                            "key-field": "id",
                            options: _vm.smallCardsVertOptions,
                            items: _vm.mainServices,
                            navBetween: true
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "slide",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("card-section-vert", {
                                      staticClass: "index__catalog-card",
                                      attrs: {
                                        header: item.name,
                                        description: item.short_description,
                                        image: item.image,
                                        href: item.link_on_main
                                          ? item.link_on_main
                                          : item.detail_url
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            929475307
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.student_companies && _vm.student_companies.length > 0
          ? _c(
              "section",
              { staticClass: "section index__section index__student-company" },
              [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c("div", { staticClass: "index__student-company-hl" }, [
                      _vm._v("Наши студенты")
                    ]),
                    _vm._v(" "),
                    _c(
                      "transition",
                      { attrs: { name: "fade-in", mode: "out-in" } },
                      [
                        !_vm.isTablet
                          ? _c("v-list", {
                              staticClass: "index__partner-list",
                              attrs: {
                                items: _vm.student_companies,
                                "key-field": "id"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("card-partner", {
                                          staticClass: "index__partner-card",
                                          attrs: { image: item.image }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1412836395
                              )
                            })
                          : _c("slider", {
                              staticClass: "index__partner-slider",
                              attrs: {
                                items: _vm.student_companies,
                                navBetween: true,
                                twoCol: true
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "slide",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("card-partner", {
                                          staticClass: "index__partner-card",
                                          attrs: { image: item.image }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1515843561
                              )
                            })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-button",
                      {
                        staticClass: "btn--white index__teacher-company-btn",
                        attrs: { href: "/students" }
                      },
                      [_vm._v("Узнать больше")]
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.videoReviews && _vm.videoReviews.length > 0
          ? _c(
              "section",
              { staticClass: "section index__section index__videos" },
              [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade-in", mode: "out-in" } },
                      [
                        _c("slider", {
                          staticClass: "index__videos-slider",
                          attrs: {
                            title: "Отзывы студентов",
                            items: _vm.videoReviews,
                            hrefAll: "/reviews"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "addition",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-button",
                                      {
                                        staticClass:
                                          "btn--white btn--video index__videos-btn",
                                        attrs: {
                                          iconName: "youtube",
                                          target: "_blank",
                                          rel: "noopener noreferrer",
                                          href:
                                            _vm.layout_StaticBlock
                                              .more_reviews_on_youtube_link
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.isTabletLg
                                              ? "Ещё отзывы"
                                              : "Ещё отзывы на Youtube"
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "slide",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("card-video-review", {
                                      staticClass: "index__videos-card",
                                      attrs: {
                                        title: item.title,
                                        src: item.video
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3217167034
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.iwengoNumbers && _vm.iwengoNumbers.length > 0
          ? _c(
              "section",
              { staticClass: "section index__section index__benefit" },
              [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c("div", { staticClass: "index__benefit-hl" }, [
                      _vm._v("Цифры и факты")
                    ]),
                    _vm._v(" "),
                    _vm._m(1)
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "section index__section index__pseudo-btn" },
          [
            _c("div", { staticClass: "container text-center" }, [
              _c("div", { staticClass: "btn" }, [
                _vm._v(
                  "Наши студенты проходят стажировку в известных компаниях. Для компаний это возможность решить задачи E-commerce силами стажёров при\nподдержке профессиональных кураторов и преподавателей бизнес-школы, а для студентов - получить практику в реальном бизнесе и отзыв о своей работе\n                    "
                ),
                _c(
                  "a",
                  { attrs: { href: "/about/besplatnaya-stazhirovka" } },
                  [
                    _vm._v("Узнать подробнее "),
                    _c("v-svg", {
                      staticClass: "svg-line",
                      attrs: { name: "line" }
                    })
                  ],
                  1
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.cooperation
          ? _c(
              "section",
              { staticClass: "section index__section index__cooperation" },
              [
                _c("div", { staticClass: "container" }, [
                  _c("div", {
                    staticClass: "index__partner-hl",
                    domProps: { innerHTML: _vm._s(_vm.cooperation.title) }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "index__cooperation-desc index__cooperation-desc-text"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "index__cooperation-desc-col desc-col__left"
                        },
                        [
                          _vm._v(
                            'С 2020 года школа является образовательным партнером Администрации Президента Республики Татарстан, Центра Электронной Торговли "Маркетплейс.Легко», Минпромторга РФ: более 4000 предпринимателей из 15-ти районов республики Татарстан прошли обучение продажам на электронных площадках (маркетплейсах).'
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "index__cooperation-desc-col desc-col__right"
                        },
                        [
                          _vm._v(
                            'С 2021 года iWENGO является оператором образовательных программ национальных проектов "Профессиональный цифровой сертификат" и "Демография" - более 3000 наших  выпускников освоили новые цифровые профессии.\n                            '
                          ),
                          _c("div", [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: {
                                  href: "/about/programmy-obucheniya-b2g"
                                }
                              },
                              [
                                _vm._v("УЗНАТЬ ПОДРОБНЕЕ "),
                                _c("v-svg", {
                                  staticClass: "svg-line",
                                  attrs: { name: "line" }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.cooperation.gallery_image.length == 1
                    ? _c("div", { staticClass: "index__cooperation-desc" }, [
                        _c("img", {
                          attrs: {
                            "data-blink-src": _vm.cooperation.gallery_image[0]
                          }
                        })
                      ])
                    : _c(
                        "div",
                        { staticClass: "index__cooperation-desc" },
                        [
                          _c("slider", {
                            staticClass: "index__cooperation-slider",
                            attrs: {
                              items: _vm.cooperation.gallery_image,
                              navBetween: true,
                              options: _vm.cooperationSliderOptions
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "slide",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("img", {
                                        staticClass: "index__cooperation__img",
                                        attrs: { "data-blink-src": item }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3882251502
                            )
                          })
                        ],
                        1
                      )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.teachers && _vm.teachers.length > 0
          ? _c(
              "section",
              {
                staticClass:
                  "section index__section index__teacher index__bottom"
              },
              [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade-in", mode: "out-in" } },
                      [
                        _c("slider", {
                          staticClass: "index__teacher-slider",
                          attrs: {
                            title: "Наши преподаватели",
                            "key-field": "id",
                            linkText: _vm.isTablet
                              ? "Все"
                              : "Все преподаватели",
                            options: _vm.smallCardsOptions,
                            items: _vm.teachers,
                            hrefAll: "/teachers",
                            navBetween: true
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "slide",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("card-teacher", {
                                      staticClass: "index__teacher-card",
                                      attrs: {
                                        teacher: item,
                                        "is-small": true
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "after",
                                            fn: function() {
                                              return [
                                                item.course_count > 0
                                                  ? _c(
                                                      "v-link",
                                                      {
                                                        attrs: {
                                                          href:
                                                            item.course_list_url
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              item.course_count
                                                            ) +
                                                            "\n                                            " +
                                                            _vm._s(
                                                              _vm.pluralForm(
                                                                item.course_count,
                                                                [
                                                                  "курс",
                                                                  "курса",
                                                                  "курсов"
                                                                ]
                                                              )
                                                            ) +
                                                            "\n                                            преподавателя\n                                        "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1155200716
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("ozon-popup")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("v-list", {
      staticClass: "index__course-list",
      attrs: { items: _vm.mainCourses, "key-field": "id" },
      scopedSlots: _vm._u([
        {
          key: "item",
          fn: function(ref) {
            var course = ref.item
            var index = ref.index
            return [
              _c("card-course", {
                staticClass: "index__course-card",
                attrs: {
                  isSmall: _vm.isTabletLg,
                  course: course,
                  isDsrc: true,
                  seoPosition: index,
                  seoList: "Homepage"
                }
              })
            ]
          }
        }
      ])
    })
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("v-list", {
      class: [
        "index__benefit-list",
        _vm.iwengoNumbers.length % 4 == 0 ? "index__benefit-list__four" : ""
      ],
      attrs: { "key-field": "id", items: _vm.iwengoNumbers },
      scopedSlots: _vm._u(
        [
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("card-benefit", {
                  staticClass: "index__benefit-card",
                  attrs: { header: item.title, description: item.description }
                })
              ]
            }
          }
        ],
        null,
        false,
        3950778713
      )
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }