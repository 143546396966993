<template>
    <div class="card-partner">
        <img class="lazyload card-partner__img" :data-blink-src="image" />
        <v-clamp class="card-partner__desc" autoresize :maxLines="maxLines">{{ description }}</v-clamp>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import './card-partner.css';

export default {
    name: 'card-partner',
    props: {
        image: String,
        description: String,
    },
    components: {
        VClamp,
    },
    computed: {
        maxLines() {
            return this.$mq.tablet ? 3 : 2;
        },
    },
};
</script>
