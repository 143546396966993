import { render, staticRenderFns } from "./card-section.vue?vue&type=template&id=624c5d38&"
import script from "./card-section.vue?vue&type=script&lang=js&"
export * from "./card-section.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev_iwengo_ecom/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('624c5d38')) {
      api.createRecord('624c5d38', component.options)
    } else {
      api.reload('624c5d38', component.options)
    }
    module.hot.accept("./card-section.vue?vue&type=template&id=624c5d38&", function () {
      api.rerender('624c5d38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/card-section/card-section.vue"
export default component.exports