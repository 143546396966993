<template>
    <div class="card-course" :class="{ 'card-course--small': isSmall, 'card-course--tablet': isTablet }">
        <div class="card-course__tags" v-if="isSmall">
            <info-tag class="card-course__tag" v-if="course.is_bestseller" type="pink">Акция</info-tag>
            <info-tag class="card-course__tag" v-if="course.is_new" type="blue">Новинка</info-tag>
            <info-tag class="card-course__tag" v-if="course.is_hit" type="blue2">Хит</info-tag>
            <info-tag class="card-course__tag" v-if="course.is_special_offer" type="magenta">Special Offer</info-tag>
        </div>
        <a class="card-course__detail" :href="course.detail_url" @click="sendGtag($event)">
            <!--   course.image_on_main - картинка для главной   -->
            <!--   videoOnMain - видео для главной (может отсутствовать)  -->
            <img class="lazyload card-course__detail-img" :data-blink-src="cardImage" alt />
        </a>
        <div class="card-course__body">
            <div class="card-course__top">
                <info-label v-if="!(!course.nearest_span && course.type_id == 1)" class="card-course__date">
                    {{ course.nearest_span ? course.nearest_span.short_title : 'Нет мест' }}
                </info-label>
                <info-panel v-else></info-panel>
                <info-panel class="card-course__panel" :reviewsCount="course.total_review_count" :rating="course.rating">
                    <template v-if="!isSmall" v-slot:tags="{ tagClass }">
                        <info-tag :class="tagClass" v-if="course.is_bestseller" type="pink">Акция</info-tag>
                        <info-tag :class="tagClass" v-if="course.is_new" type="blue">Новинка</info-tag>
                        <info-tag :class="tagClass" v-if="course.is_hit" type="blue">Хит</info-tag>
                        <info-tag :class="tagClass" v-if="course.is_special_offer" type="magenta">Special Offer</info-tag>
                    </template>
                </info-panel>
                <info-group class="card-course__info" :items="infoGroup" v-if="!isSmall" />
            </div>
            <v-link class="card-course__link" :href="course.detail_url" @click="sendGtag($event)">
                <v-clamp autoresize :max-lines="maxLines">{{ course.name }}</v-clamp>
            </v-link>
            <info-group class="card-course__info" :items="infoGroup" v-if="isSmall" />
            <info-html  v-if="isDsrc" class="line-clamp card-course__info " v-html="course.short_description">
            </info-html>
            <price-panel
                class="card-course__price"
                :price="price(course.nearest_span, course)"
                :oldPrice="priceOld(course.nearest_span, course)"
            >
                <template v-slot:btns="{ btnClass }">
                    <v-button :class="btnClass" :href="course.detail_url">Подробнее</v-button>
                    <v-button
                        v-if="!isSmall"
                        :class="['btn--wishlist', btnClass]"
                        :icon-name="wishlistIcon"
                        @click="wishlist_ToggleWishlist({ type: 'course', id: course.id })"
                    >
                        {{ isSmall || isDesktopLg ? '' : wishlistBtnText }}
                    </v-button>
                </template>
            </price-panel>
        </div>
    </div>
</template>

<script>
import VButton from '../controls/VButton/VButton.vue';
import VLink from '../controls/VLink/VLink.vue';
import VClamp from 'vue-clamp';

import InfoHtml from '../info-html/info-html.vue';
import InfoTag from '../info-tag/info-tag.vue';
import InfoLabel from '../info-label/info-label.vue';
import InfoGroup from '../info-group/info-group.vue';
import InfoPanel from '../info-panel/info-panel.vue';
import PricePanel from '../PricePanel/PricePanel.vue';

import CourseMixin from '../../mixins/course';
import WishlistMixin from '../../mixins/wishlist.js';

import './card-course.css';
import Seo from "../../../scripts/seo";

export default {
    name: 'card-course',
    mixins: [CourseMixin, WishlistMixin()],
    props: {
        course: Object,
        isSmall: {
            type: Boolean,
            default: true,
        },
        isDsrc: {
            type: Boolean,
            default: false,
        },
        seoList: {
            type: String,
            default: 'Category',
        },
        seoPosition: {
            type: Number,
            default: 0,
        },
    },
    components: {
        VButton,
        VLink,
        VClamp,

        PricePanel,
        InfoHtml,
        InfoTag,
        InfoLabel,
        InfoPanel,
        InfoGroup,
    },
    computed: {
        isDesktopLg() {
            return this.$mq.desktopLg;
        },
        wishlistIcon() {
            return this.wishlist_InWishlist('course', this.course.id) ? 'heart' : 'wishlist';
        },
        wishlistBtnText() {
            return this.wishlist_InWishlist('course', this.course.id) ? 'В избранном' : 'В избранное';
        },
        infoGroup() {
            return [
                `${this.course.hours_total} ${this.pluralForm(this.course.hours_total, ['час', 'часа', 'часов'])}`,
                this.course.type.name,
            ];
        },
        maxLines() {
            return this.$mq.tablet ? 3 : 2;
        },
        videoOnMain() {
            if (this.course.gallery_video.length > 0) {
                return this.course.gallery_video[0].url;
            }
        },
        cardImage() {
            return this.isSmall ? this.course.image : this.course.image_on_main;
        },
        isTablet() {
            return this.$mq.tablet;
        },
    },
    methods: {
        sendGtag(e) {
            if(this.seoList === '') return;

            Seo.click(this.$gtag, this.course, 'course', this.seoPosition + 1, this.seoList);
        }
    }
};
</script>
