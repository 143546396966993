var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "modal" } },
    [
      _vm.isShowed && _vm.modal_IsOpen
        ? _c(
            "modal",
            {
              staticClass: "ozon-modal",
              on: {
                close: function($event) {
                  return _vm.hide()
                }
              }
            },
            [
              _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://www.ozon.ru/seller/iwengo-52715/products/?bx_sender_conversion_id=68618&utm_source=site&utm_medium=referral&utm_campaign=banner",
                      target: "_blank"
                    },
                    on: {
                      click: function($event) {
                        _vm.hide()
                        _vm.clickPopup()
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../images/inhtml/skidka_470x462.png")
                      }
                    })
                  ]
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }