<template>
    <div class="slider">
        <div :class="['slider__header', (title || hrefAll)? '': 'mb-0']">
            <div class="slider__title-block">
                <div v-if="title" class="slider__title">{{ title }}</div>
                <a v-if="hrefAll" class="link slider__link" :href="hrefAll">{{ linkText }}</a>
            </div>
            <div class="slider__addition">
                <slot name="addition" />
            </div>
            <div :class="navBetween ? 'slider__navigation-between' : 'slider__navigation'" v-if="showNavigation">
                <v-button ref="prevBtn" class="btn--arrow btn--arrow-left slider__navigation-btn" iconName="line" />
                <v-button ref="nextBtn" class="btn--arrow btn--arrow-right slider__navigation-btn" iconName="line" />
            </div>
        </div>
        <swiper v-if="swiperOption" ref="slider" class="slider__slider" :options="swiperOption">
            <swiper-slide class="slider__slide" :class="twoCol ? 'slider__slide-two' : ''" v-for="(item, index) in items" :key="item[keyField] || index">
                <slot name="slide" :item="item" :index="index" />
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import VButton from '../VButton/VButton.vue';

import 'swiper/dist/css/swiper.css';
import './slider.css';

const defaultOptions = {
    slidesPerView: 3,
    spaceBetween: 30,
    observer: true,
    observeParents: true,
    grabCursor: true,
    breakpoints: {
        1279: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        767: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
    },
};

export default {
    name: 'slider',
    components: {
        swiper,
        swiperSlide,
        VButton,
    },
    props: {
        title: String,
        linkText: { type: String, default: 'Все отзывы' },
        hrefAll: String,
        items: { type: Array, default: [] },
        keyField: String,
        options: Object,
        navBetween: Boolean,
        twoCol: Boolean,
    },
    data() {
        return {
            swiperOption: null,
        };
    },
    computed: {
        showNavigation() {
            return (this.items.length > 1 && this.items.length > (this.options || defaultOptions).slidesPerView);
        }
    },
    mounted() {
        const navigation = (this.showNavigation)?{
            navigation: {
                nextEl: this.$refs.nextBtn.$el,
                prevEl: this.$refs.prevBtn.$el,
            },
        } : null;

        this.swiperOption = Object.assign({}, this.options || defaultOptions, navigation);
    },
};
</script>
