var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "price-panel" }, [
    _c("div", { staticClass: "price-panel__prices" }, [
      _vm.showOld && _vm.oldPrice > 0 && _vm.oldPrice !== _vm.price
        ? _c(
            "div",
            { staticClass: "price-panel__price price-panel__price--old" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.preparePrice(_vm.oldPrice)) +
                  "\n            "
              ),
              _c("span", [_vm._v("₽")])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.price > 0
        ? _c("div", { staticClass: "price-panel__price" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.preparePrice(_vm.price)) +
                "\n            "
            ),
            _c("span", [_vm._v("₽")])
          ])
        : _vm.price < 0
        ? _c("div", { staticClass: "price-panel__price" }, [
            _vm._v("\n            Цена по запросу\n        ")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "price-panel__btns" },
      [_vm._t("btns", null, { btnClass: "price-panel__btn" })],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }