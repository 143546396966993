<template>
  <transition name="modal">
    <modal 
      v-if="isShowed && modal_IsOpen"
      @close="hide()"
      class="ozon-modal"
    >
      <div slot="body">
        <a @click="hide(); clickPopup()" href="https://www.ozon.ru/seller/iwengo-52715/products/?bx_sender_conversion_id=68618&utm_source=site&utm_medium=referral&utm_campaign=banner" target="_blank">
          <img src="../../../images/inhtml/skidka_470x462.png">
        </a>
      </div>
    </modal>
  </transition>
</template>

<script>
import './ozon-popup.css';

import Modal from '../controls/modal/modal.vue';

import ModalMixin from '../../mixins/modal';

export default {
  name: 'OzonPopup',
  mixins: [ModalMixin('ozon_popup')],
  data () {
    return {
      isShowed: false,
    }
  },
  components: {
    Modal,
  },
    methods: {
    show () {
      window.setInterval(() => {
        if (!this.isShowed && !this.$cookie.get('ozon-popup')) {
          this.isShowed = !this.isShowed;
          this.modal_ChangeState({ name: 'ozon_popup', open: true });
          this.$cookie.set('ozon-popup', this.isShowed , 30);
        }
      }, 10000)
    },
    hide () {
      this.modal_ChangeState({ name: 'ozon_popup', open: false });
    },
    clickPopup () {
      this.$metrika.reachGoal('click_promo_iwengo_ozon_popup');
      this.$gtag.event('promo_iwengo_ozon_popup', { 'event_category' : 'click' });
    },
  },
  mounted() {
    //this.show();
  }
}
</script>
