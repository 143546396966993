<template >
    <div class="info-label" :class="{ [`info-label--${type}`]: type }">
        <slot />
    </div>
</template>

<script>
import './info-label.css';

export default {
    name: 'info-label',
    props: {
        type: {
            type: String,
            default: 'grey',
        },
    },
};
</script>
